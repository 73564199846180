.contact-container {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 15vh;
    //background-color: grey;
}

.contact-content {
    width: 100%;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    margin-top: 1.5em;
}
.contact-form-container {
    //background-color: lightblue;
    display: grid;
    place-items: center;
}
.contact-form {
    //background-color: lightcoral;
    display: flex;
    flex-direction: column;
    max-width: 560px;
}

.form-inputs {
    //background-color: lightgreen;
}

.contact-btn {
    //background-color: lightseagreen;
    width: 100%;
    display: flex;
    align-items: center;
}

.contact-details {
    //background-color: grey;
    width: 100%;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
}



.contact-details-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.map-container {
    width: 100%;
    height: 40vh;
    margin-top: 5vh;
}