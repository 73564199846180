.about-container {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 10vh;
}

.about-content {
    width: 100%;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    margin-top: 1.5em;
}

.about-title {
    //background-color: lightcoral;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;
}

.about-summary {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.quick-info {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.education {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1em 0;
    //background-color: lightblue;
}

.summary-paper {
    padding: 1em 1.5em;
    //background-color: #C5C6C7;
    //color: #45a29e !important;
    //font-weight: bolder;
    min-height: 300px;
    max-width: 500px;
}

.download-cv {
    //background-color: lightblue;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.download-file {
    color: #66FCF1;
    text-decoration: none;
}

.download-file:hover {
    color: #45A29E;
}

.cv-down-btn {
    margin-top: 15px;
}

.time-working {
    width: 100%;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
}

@media screen and (max-width: 960px) {
    
}

@media screen and (max-width: 500px) {
    .time-working {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

}