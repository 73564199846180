.skills-container {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 10vh;
    padding-left: 0;
    //background-color: grey;
}

.accordian-container {
    width: 100%;
    margin-top: 5vh;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1em;
    //background-color: lightblue;
}


.accordion-content {
    //background-color: lightgreen;
}

.accordian-section {
    background-color: lightcoral;
    width: 100%;
    display: flex;
    flex-direction: row;
}