* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    background-color: #1F2833;
    display: flex;
    justify-content: center;
    max-width: 100vw; 
    box-sizing: border-box;
    overflow-x: hidden;
    font-family: 'Source Code Pro', monospace;
    font-size: 1rem;
    line-height: 1.6;
    overflow-y: auto;
    color: #66FCF1;
}

.app {
    width: 100vw;
    background-color: #1F2833;
    overflow: auto;
    color: #66FCF1;
    box-sizing: border-box;
}

/* custom scrollbar  
::-webkit-scrollbar {
    width: 0px;
}
  
::-webkit-scrollbar-track {
    background-color: transparent;
}
  
::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}
  
::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}
*/

.container {
    max-width: 1200px;
    margin: auto;
    padding: 0 2rem;
    margin-bottom: 3rem;
    margin-top: 10vh;
    position: relative;
    overflow: hidden;
}

.footer {
    width: 100vw;
    min-height: 10vh;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,.2);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //background-color: lightblue;
}
.footer-social {
    display: flex;
}

.footer-email-phone {
    display: flex;
    margin-right: 80px;
}


.alert-container {
    position: fixed;
    bottom: 5%;
    left: 5%;
    color: white;
    padding: .5em;
    border-radius: 2%;
    z-index: 4;
    display: flex;
    justify-self: center;
    align-items: center;
}

.alert-container.danger {
    background-color: #EA3546;
}

.alert-container.success {
    background-color: #659157;
}


@media screen and (max-width: 500px) {
    .footer {
        
    }
    .footer-email-phone {
        font-size: .8em;
        margin-right: 0;
    }
}